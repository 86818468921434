import * as React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {DataProvider} from "../contentful/data-provider";
import {BLOCKS, INLINES} from '@contentful/rich-text-types';
import {renderRichText} from "gatsby-source-contentful/rich-text";
import BlogPostsSmallSection from "../components/sections/_blog-posts-small-section";
import { MARKS } from "@contentful/rich-text-types";
import agoricIcon from "../images/agoric-icon.png";

import SyntaxHighlighter from "react-syntax-highlighter";
import {githubGist} from "react-syntax-highlighter/dist/esm/styles/hljs";
import Seo from "../components/customSeo";

const BlogSingle = ({data, location}) => {
    const dataProvider = new DataProvider(data);
    const post = data.contentfulBlogPost;

    const blogPosts = data.allContentfulBlogPost.nodes.filter((relatedPost) => {
        return relatedPost.slug !== post.slug;
    });

    const options = {
        renderMark: {
            [MARKS.CODE]: (node) => {
                return (
                    typeof node !== 'object' && <SyntaxHighlighter
                        language="javascript"
                        style={githubGist}
                        showLineNumbers={false}
                        children={node}
                        wrapLongLines={true}
                        useInlineStyles={true}
                    />
                );
            },
        },
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const {gatsbyImageData, description} = node.data.target
                console.log(node)
                return (
                    <GatsbyImage
                        image={getImage(gatsbyImageData)}
                        alt={description}
                    />
                )
            },
            [INLINES.HYPERLINK]: (node, children) => {
                const {uri} = node.data
                return (
                    <a href={uri} target={'_blank'} rel={'noreferrer'}>
                        {children}
                    </a>
                )
            }
        },
    }

    return (
        <Layout dataProvider={dataProvider}>
            <Helmet
                defer={false}
                title={post.title || 'Agoric'}
                meta={[
                    {
                        property: `og:title`,
                        content: post.title || 'Agoric',
                    },
                    {
                        property: `twitter:title`,
                        content: post.title || 'Agoric',
                    },
                    {
                        property: 'description',
                        content: post.excerpt && String(renderRichText(post.excerpt)[0].props.children),
                    },
                    {
                        property: 'og:description',
                        content: post.excerpt && String(renderRichText(post.excerpt)[0].props.children),
                    },
                    {
                        property: 'twitter:description',
                        content: post.excerpt && String(renderRichText(post.excerpt)[0].props.children),
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: post.image?.url,
                    },
                    {
                        property: `og:image`,
                        content: post.image?.url,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            />

            <main className='blog-single pt-24 lg:pt-24'>
                <section className={"blog-post"}>
                    <div className='container'>
                        <div className='row blogpost--title justify-content-center'>
                            <div className='col-lg-10'>
                                <h2>{post.title}</h2>
                            </div>
                        </div>
                        <div className='row blogpost--image justify-content-center'>
                            <div className='col-lg-10'>
                                <div className='decorate-container'>
                                    <div className='decorate'>
                                        <span className='decorate__item'/>
                                        <span className='decorate__item'/>
                                    </div>
                                </div>
                                <img src={`${post.image?.url}`} alt={post.title}/>
                            </div>
                        </div>
                        <div className='row justify-content-center blogpost--content'>
                            <div className='col-lg-9'>
                                <div className={"row post-head flex-nowrap align-items-center"}>
                                    <div className={"col-auto border-end pe-5"}>
                                        <div className='author row align-items-center'>
                                            <div className='col-auto author--image'>
                                                <img
                                                    src={agoricIcon}
                                                    width='36px'
                                                    alt=''
                                                />
                                            </div>
                                            <div className='col-auto author--content'>
                                                <p className='name mb-0'>{post?.author}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-auto border-end px-5"}>
                                        <div className='date'>{post?.publishedAt}</div>
                                    </div>
                                    <div className={"col-auto ps-5"}>
                                        {post.tags &&
                                            post.tags.split(",").map((tag, tagIndex) => {
                                                return (
                                                    <div className='tag' key={tagIndex}>
                                                        {tag}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>

                                <div className='post-content'>

                                    {post.body && renderRichText(
                                        post.body,options
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <BlogPostsSmallSection
                    content={{
                        title: "Related Articles",
                        elements: blogPosts,
                        ctaButton: false,
                    }}
                />
            </main>
        </Layout>
    );
};

export default BlogSingle;

export const query = graphql`
    query BlogPostBySlug($slug: String!, $categorySlug: String!) {
        contentfulBlogPost(slug: { eq: $slug }) {
            id
            title
            slug
            image {
                title
                url
            }
            author
            publishedAt(formatString: "MMMM DD, YYYY")
            tags
            category {
                title
                slug
            }
            body {
                raw
                references {
                    contentful_id
                    title
                    description
                    gatsbyImageData
                    __typename
                }
            }
            excerpt {
                raw
            }
        }
        allContentfulBlogPost(filter: { category: { slug: { eq: $categorySlug } } }, limit: 3) {
            nodes {
                id
                title
                slug
                image {
                    title
                    url
                }
                author
                publishedAt(formatString: "MMMM DD, YYYY")
                tags
                category {
                    title
                    slug
                }
                body {
                    raw
                }
                excerpt {
                    raw
                }
            }
        }
    }
`;
